import "./App.css";

import {
  AdditionalPhonemeInfo,
  Character,
  EmotionEvent,
  HistoryItem,
  InworldConnectionService,
  InworldPacket,
} from "@inworld/web-sdk";
import { Grid } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Circles } from "react-loader-spinner";
import http from "./app/services/http";
import { Chat } from "./app/chat/Chat";
import { Avatar } from "./app/components/3dAvatar/Avatar";
import { CircularRpmAvatar } from "./app/components/CircularRpmAvatar";
import { Layout } from "./app/components/Layout";
import {
  ChatWrapper,
  MainWrapper,
  SimulatorHeader,
} from "./app/components/Simulator";
import { ConfigView } from "./app/configuration/ConfigView";
import { InworldService } from "./app/connection";
import {
  get as getConfiguration,
  save as saveConfiguration,
} from "./app/helpers/configuration";
import { CHAT_VIEW, Configuration, EmotionsMap } from "./app/types";
import { config } from "./config";
import * as defaults from "./defaults";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface CurrentContext {
  characters: Character[];
  chatting: boolean;
  connection?: InworldConnectionService;
}

function App() {
  const formMethods = useForm<Configuration>({ mode: "onChange" });
  const [newAudioSource, setNewAudioSource] = useState<string>("");
  const [initialized, setInitialized] = useState(false);
  const [connection, setConnection] = useState<InworldConnectionService>();
  const [character, setCharacter] = useState<Character>();
  const [characters, setCharacters] = useState<Character[]>([]);
  const [chatHistory, setChatHistory] = useState<HistoryItem[]>([]);
  const [video, setVideo] = useState("");
  const [idleStartTime, setIdleStartTime] = useState("");
  const [isMuted, setisMuted] = useState(false);
  const [idleEndTime, setIdleEndTime] = useState("");
  const [movementStartTime, setMovementStartTime] = useState("");
  const [speechState, setSpeechState] = useState("");
  const [chatting, setChatting] = useState(false);
  const [chatView, setChatView] = useState(CHAT_VIEW.TEXT);
  const [phonemes, setPhonemes] = useState<AdditionalPhonemeInfo[]>([]);
  const [emotionEvent, setEmotionEvent] = useState<EmotionEvent>();
  const [avatar, setAvatar] = useState("");
  const [emotions, setEmotions] = useState<EmotionsMap>({});
  const [img, setImg] = useState("");
  const [fullHistory, setfullHistory] = useState<boolean>(true);
  const [chatBar, setChatBar] = useState<boolean>(true);
  const [language, setLanguage] = useState("");
  const [gender, setGender] = useState("");
  const [languageCode, setLanguageCode] = useState("");
  const [canvasBackground, setCanvasBackground] = useState("");
  const [myKey, setKey] = useState<string | undefined>("");
  const [backgroundColor, setbackgroundColor] = useState<string | undefined>(
    ""
  );
  const [gradientColor, setGradientColor] = useState<string | undefined>("");
  const [textColor, setTextColor] = useState<string | undefined>("");
  const [characterColor, setCharacterColor] = useState<string | undefined>("");
  const [text, setText] = useState<string | undefined>("");
  const [playerName, setPlayer] = useState<string | undefined>("");

  let listenersAdded = true;
  let flag = false;
  let audioQueue: Uint8Array[] = [];
  const audioRef = useRef<HTMLAudioElement>(null);
  const stateRef = useRef<CurrentContext>();
  stateRef.current = {
    characters,
    chatting,
    connection,
  };
  useEffect(() => {
    sessionStorage.setItem("translateLanguage", language);
    sessionStorage.setItem("translateGender", gender);
    sessionStorage.setItem("translateLanguageCode", languageCode);
  }, [language]);
  const onHistoryChange = (history: HistoryItem[]) => {
    let translateLanguage = sessionStorage.getItem("translateLanguage");
    let translateGender = sessionStorage.getItem("translateGender");
    let translateLanguageCode = sessionStorage.getItem("translateLanguageCode");
    let lastElement = history.slice(-1);
    if (
      lastElement[0].source.isCharacter === true &&
      lastElement[0].type === "actor"
    ) {
      let reqdata = lastElement[0].text;
      const playNextAudio = async (): Promise<void> => {
        if (audioQueue.length === 0) {
          flag = true;
          setSpeechState("idle");
          return;
        }
        flag = false;
        setSpeechState("speaking");
        const audioData = audioQueue.shift();
        if (!audioData) {
          return; // Check if audioData is undefined, and skip playback if it is
        }

        const blob = new Blob([audioData], { type: "audio/mpeg" });
        if (audioRef.current) {
          audioRef.current.src = URL.createObjectURL(blob);
        }
        if (listenersAdded) {
          audioRef.current?.addEventListener("ended", () => {
            playNextAudio();
          });

          audioRef.current?.addEventListener("error", (error) => {
            console.error("Error:", error);
            playNextAudio();
          });
          listenersAdded = false;
        }
        audioRef.current?.play(); // Play audio if it's paused
      };
      const fetchData = async (): Promise<void> => {
        try {
          const response = await http.post(
            "/speech/textToSpeech",
            {
              text: reqdata,
              gender: translateGender,
              languageCode: translateLanguageCode,
              language: translateLanguage,
            },
            {
              headers: { "Content-Type": "application/json" },
            }
          );
          const audioData = new Uint8Array(response.data.data);
          audioQueue.push(audioData);
          // if (audioRef.current) {
          //   audioRef.current.muted = false;
          // }
          // Start playback if audio is not currently playing
          if (flag || listenersAdded) {
            playNextAudio();
          } else {
          }
        } catch (err) {
          console.error("Error:", err);
        }
      };
      fetchData();
    }
    setChatHistory(history);
  };

  const openConnection = useCallback(async () => {
    let form: Configuration = JSON.parse(
      localStorage.getItem("inworldConfiguration") || "{}"
    );
    setChatting(true);
    setChatView(form.chatView!);
    setImg(form.img?.name!);
    setVideo(form.video?.name!);
    setIdleStartTime(form.idleStartTime?.name!);
    setisMuted(form.isMuted?.name!);
    setIdleEndTime(form.idleEndTime?.name!);
    setMovementStartTime(form.movementStartTime?.name!);
    setSpeechState("idle");
    setbackgroundColor(form.backgroundColor?.name!);
    setGradientColor(form.gradientColor?.name!);
    setTextColor(form.textColor?.name!);
    setCharacterColor(form.characterColor?.name!);
    setText(form.characterText?.name!);
    setPlayer(form.player?.name!);
    setKey(form.key?.name);
    const service = new InworldService({
      onHistoryChange,
      capabilities: {
        ...(form.chatView === CHAT_VIEW.AVATAR && { phonemes: true }),
        emotions: true,
        narratedActions: true,
      },
      sceneName: form.scene?.name!,
      playerName: form.player?.name!,
      onPhoneme: (phonemes: AdditionalPhonemeInfo[]) => {
        setPhonemes(phonemes);
      },
      onReady: async () => {
        console.log("Ready!");
      },
      onDisconnect: () => {
        console.log("Disconnect!");
      },
      onMessage: (inworldPacket: InworldPacket) => {
        if (
          inworldPacket.isEmotion() &&
          inworldPacket.packetId?.interactionId
        ) {
          setEmotionEvent(inworldPacket.emotions);
          setEmotions((currentState) => ({
            ...currentState,
            [inworldPacket.packetId.interactionId]: inworldPacket.emotions,
          }));
        }
      },
    });
    const characters = await service.connection.getCharacters();
    const character = characters.find(
      (c: Character) => c.resourceName === form.character?.name
    );

    if (character) {
      service.connection.setCurrentCharacter(character);

      const assets = character?.assets;
      const rpmImageUri = assets?.rpmImageUriPortrait;
      const avatarImg = assets?.avatarImg;
      setAvatar(avatarImg || rpmImageUri || "");
    }

    setConnection(service.connection);

    setCharacter(character);
    setCharacters(characters);
  }, [formMethods, onHistoryChange]);

  const stopChatting = useCallback(async () => {
    // Disable flags
    setChatting(false);

    // Stop audio playing and capturing
    connection?.player?.stop();
    connection?.player?.clear();
    connection?.recorder?.stop();

    // Clear collections
    setChatHistory([]);

    // Close connection and clear connection data
    connection?.close();
    setConnection(undefined);
    setCharacter(undefined);
    setCharacters([]);
  }, [connection]);

  const resetForm = useCallback(() => {
    formMethods.reset({
      ...defaults.configuration,
    });
    saveConfiguration(formMethods.getValues());
  }, [formMethods]);

  useEffect(() => {
    const configuration = getConfiguration();
    formMethods.reset({
      ...(configuration
        ? (JSON.parse(configuration) as Configuration)
        : defaults.configuration),
    });

    setInitialized(true);
  }, [formMethods]);

  const content = chatting ? (
    <>
      {character ? (
        <MainWrapper>
          <ChatWrapper>
            {chatView === "Avatar" && (
              <Avatar
                emotionEvent={emotionEvent}
                phonemes={phonemes}
                visible={chatView === CHAT_VIEW.AVATAR}
                url={
                  config.RPM_AVATAR ||
                  character.assets.rpmModelUri ||
                  defaults.DEFAULT_RPM_AVATAR
                }
                bG={canvasBackground}
              />
            )}
            <SimulatorHeader>
              <Grid container>
                {/* <Grid item sm={6}>
                  <Button
                    startIcon={<ArrowBackRounded />}
                    onClick={stopChatting}
                    variant="outlined"
                  >
                    Back to settings
                  </Button>
                </Grid> */}
                {chatView === CHAT_VIEW.TEXT && (
                  <Grid item sm={6}>
                    {avatar && (
                      <CircularRpmAvatar
                        src={avatar}
                        name={character.getDisplayName()}
                        size="48px"
                        sx={{ display: ["none", "flex"] }}
                      />
                    )}
                  </Grid>
                )}
              </Grid>
            </SimulatorHeader>
            <Chat
              PlayerName={playerName}
              chatView={chatView}
              speechState={speechState}
              chatHistory={chatHistory}
              connection={connection!}
              onStopChatting={stopChatting}
              emotions={emotions}
              chatBar={chatBar}
              img={img}
              language={language}
              fullHistory={fullHistory}
              video={video}
              idleStartTime={idleStartTime}
              idleEndTime={idleEndTime}
              movementStartTime={movementStartTime}
              newAudioSource={newAudioSource}
              audioRef={audioRef}
              isMuted={isMuted}
              backgroundColor={backgroundColor}
              gradientColor={gradientColor}
              textColor={textColor}
              characterColor={characterColor}
              characterText={text}
              myKey={myKey}
            />
          </ChatWrapper>
        </MainWrapper>
      ) : (
        <div className="loader-wrapper">
          {" "}
          <Circles color="#A200F6" height="100" width="100" />
        </div>
      )}
    </>
  ) : (
    <ConfigView
      canStart={formMethods.formState.isValid}
      onStart={openConnection}
      onResetForm={resetForm}
      setFullHistoryFunc={setfullHistory}
      setBg={setCanvasBackground}
      setLanguage={setLanguage}
      setGender={setGender}
      setLanguageCode={setLanguageCode}
      setChatBar={setChatBar}
    />
  );

  return (
    <FormProvider {...formMethods}>
      <Layout>{initialized ? content : ""}</Layout>
    </FormProvider>
  );
}

export default App;
