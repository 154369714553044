import { Grid } from "@mui/material";

import { save as saveConfiguration } from "../helpers/configuration";
import { CharacterName } from "./CharacterName";
import { ChatView } from "./ChatView";
import { PlayerName } from "./PlayerName";
import { SceneName } from "./SceneName";
import { Button, Form, Card } from "react-bootstrap";
import "../styles/ConfigView.css";
import { useEffect, useState } from "react";
import { Circles } from "react-loader-spinner";
import http from "../services/http";
import { useFormContext } from "react-hook-form";
import { CHAT_VIEW, Configuration } from "../types";

interface ConfigViewProps {
  canStart: boolean;
  onStart: () => Promise<void>;
  onResetForm: () => void;
  setFullHistoryFunc: React.Dispatch<React.SetStateAction<boolean>>;
  setChatBar: React.Dispatch<React.SetStateAction<boolean>>;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  setLanguageCode: React.Dispatch<React.SetStateAction<string>>;
  setGender: React.Dispatch<React.SetStateAction<string>>;
  setBg: React.Dispatch<React.SetStateAction<string>>;
}

type data = {
  key: string;
  characterName: string;
  sceneName: string;
  text: string;
  logo: string;
  video: string;
  idleStartTime: string;
  idleEndTime: string;
  speechState: string;
  movementStartTime: string;
  textColor: string;
  gradientColor: string;
  characterColor: string;
  chat_view: string;
  chat_history: boolean;
  chat_bar: boolean;
  backgroundColor: string;
  language: string;
  voiceName: string;
  gender: string;
  isMuted: boolean;
};

export const ConfigView = (props: ConfigViewProps) => {
  const { getValues, register } = useFormContext<Configuration>();
  const [Data, setData] = useState(false);
  const [loader, setLoader] = useState(true);
  const [text, setText] = useState("");
  const [logo, setLogo] = useState("");
  const [video, setVideo] = useState("");
  const [idleStartTime, setIdleStartTime] = useState("");
  const [isMuted, setisMuted] = useState(false);
  const [idleEndTime, setIdleEndTime] = useState("");
  const [movementStartTime, setMovementStartTime] = useState("");
  const [textColor, setTextColor] = useState("");
  const [gradientColor, setGradientColor] = useState("");
  const [characterColor, setCharacterColor] = useState("");
  const [backgroundColor, setbackgroundColor] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const name = urlParams && urlParams.get("name");
        const key = window.location.pathname.split("/", 2)[1];
        let newData = {
          name,
          key,
        };
        const result = await http.post("/character/initialData", newData, {
          headers: { "Content-Type": "application/json" },
        });

        const data: data = await result.data.data;
        if (!data && !key) {
          setLoader(false);
          setData(true);
        }
        props.setFullHistoryFunc(data.chat_history);
        props.setChatBar(data.chat_bar);
        setisMuted;
        props.setBg(data.backgroundColor);
        props.setLanguage(data.language);
        props.setGender(data.gender.toUpperCase());
        props.setLanguageCode(data.voiceName);
        let characterText = data.text;
        let logo = data.logo;
        let video = data.video;
        let idleStartTime = data.idleStartTime;
        idleStartTime = JSON.stringify(idleStartTime);
        let idleEndTime = data.idleEndTime;
        idleEndTime = JSON.stringify(idleEndTime);
        let movementStartTime = data.movementStartTime;
        setText(text);
        setLogo(logo);
        setVideo(video);
        setIdleStartTime(idleStartTime);
        setisMuted(data.isMuted);
        setIdleEndTime(idleEndTime);
        setMovementStartTime(movementStartTime);
        setTextColor(data.textColor);
        setGradientColor(data.gradientColor);
        setCharacterColor(data.characterColor);
        setbackgroundColor(data.backgroundColor);
        let charName = data.characterName;
        let sceneName = data.sceneName;
        let chat_view = data.chat_view;
        setLoader(false);
        if (name) {
          saveConfiguration({
            ...getValues(),
            img: { name: logo },
            video: { name: video },
            idleStartTime: { name: idleStartTime },
            isMuted: { name: data.isMuted },
            backgroundColor: { name: data.backgroundColor },
            gradientColor: { name: data.gradientColor },
            textColor: { name: data.textColor },
            characterText: { name: data.text },
            characterColor: { name: data.characterColor },
            idleEndTime: { name: idleEndTime },
            movementStartTime: { name: movementStartTime },
            character: { name: charName },
            scene: { name: sceneName },
            player: { name: name },
            key: { name: data.key },
            chatView:
              chat_view === "Avatar"
                ? CHAT_VIEW.AVATAR
                : chat_view === "Video"
                ? CHAT_VIEW.VIDEO
                : CHAT_VIEW.TEXT,
          });

          props.onStart();
        } else {
          saveConfiguration({
            ...getValues(),
            img: { name: logo },
            video: { name: video },
            idleStartTime: { name: idleStartTime },
            isMuted: { name: data.isMuted },
            backgroundColor: { name: data.backgroundColor },
            gradientColor: { name: data.gradientColor },
            textColor: { name: data.textColor },
            characterText: { name: data.text },
            characterColor: { name: data.characterColor },
            idleEndTime: { name: idleEndTime },
            movementStartTime: { name: movementStartTime },
            character: { name: charName },
            scene: { name: sceneName },
            chatView:
              chat_view === "Avatar"
                ? CHAT_VIEW.AVATAR
                : chat_view === "Video"
                ? CHAT_VIEW.VIDEO
                : CHAT_VIEW.TEXT,
          });
        }
      } catch (error) {
        console.log(error);
        setLoader(false);
        setData(true);
      }
    };
    fetchData();
  }, [loader, Data]);
  return (
    <>
      {loader ? (
        <div className="loader-wrapper">
          {" "}
          <Circles color="#A200F6" height="100" width="100" />
        </div>
      ) : (
        <>
          {Data ? (
            window.location.replace(
              "https://www.campusxr.org/ai-representatives"
            )
          ) : (
            // <div className="noData">
            //   <img src={noData} className="errorImg" />
            //   <h1>Sorry : Data Not Found</h1>
            // </div>
            <div
              className="text-center mainDiv"
              style={{
                // backgroundImage: `linear-gradient(to right,${characterColor} , ${gradientColor} )`,
                backgroundColor: `${characterColor}`,
              }}
            >
              <Card
                className="loginCard"
                style={{ boxShadow: `${characterColor} 0px 22px 70px 4px` }}
              >
                <div
                  className="imgDiv"
                  style={{ backgroundColor: `${gradientColor}` }}
                >
                  <div className="cardBodyLeft">
                    <Card.Img variant="top" src={logo} className="loginImg" />
                    <p
                      className="leftDivText"
                      style={{ color: `${textColor}` }}
                    >
                      {text}
                    </p>
                  </div>
                </div>
                <Card.Body className="cardBody">
                  {/* <p
                  className="welcomeTag"
                  style={{
                    // backgroundImage: `linear-gradient(to right, ${characterColor} , ${gradientColor})`,
                    backgroundColor: `${gradientColor}`,
                    color: `${textColor}`,
                  }}
                >
                  Welcome
                </p> */}
                  <Card.Title
                    className="userPanelHeading"
                    style={{ color: `${characterColor}` }}
                  >
                    Enter Your Name to Start!
                  </Card.Title>
                  <Form className=" d-inline">
                    <Form.Group
                      className="mb-3"
                      controlId="formBasicPassword"
                      style={{ width: "75%", margin: "auto" }}
                    >
                      <Grid item xs={12} sm={12}>
                        <PlayerName />
                      </Grid>
                    </Form.Group>
                  </Form>
                  <Grid
                    item
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      className="resetBtn loginBtn"
                      style={{
                        backgroundColor: `${gradientColor}`,
                        // backgroundImage: `linear-gradient(to right, ${characterColor} , ${gradientColor}) `,
                        color: `${textColor}`,
                      }}
                      variant="contained"
                      onClick={props.onResetForm}
                    >
                      Reset
                    </Button>
                    <Button
                      className="startBtn loginBtn"
                      variant="contained"
                      style={{
                        backgroundColor: `${gradientColor}`,
                        // backgroundImage: `linear-gradient(to right, ${characterColor} , ${gradientColor}) `,
                        color: `${textColor}`,
                      }}
                      onClick={props.onStart}
                    >
                      Start
                    </Button>
                  </Grid>
                </Card.Body>
              </Card>
            </div>
          )}
        </>
      )}
    </>
  );
};
