import { HistoryItem, InworldConnectionService } from "@inworld/web-sdk";
import { CopyAll, Mic, Send, VolumeOff, VolumeUp } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import SmsIcon from "@mui/icons-material/Sms";
import { Box } from "@mui/system";
import { useCallback, useState, useRef } from "react";
import SettingsBackupRestoreOutlinedIcon from "@mui/icons-material/SettingsBackupRestoreOutlined";
import { CHAT_VIEW, EmotionsMap } from "../types";
import { ActionsStyled, RecordIcon } from "./Chat.styled";
import { CopyConfirmedDialog } from "./CopyConfirmedDialog";
import "../styles/Chat.css";
import { History } from "./History";
import http from "../services/http";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

interface ChatProps {
  chatView: CHAT_VIEW;
  chatHistory: HistoryItem[];
  connection: InworldConnectionService;
  emotions: EmotionsMap;
  img: string;
  video: string;
  idleStartTime: string;
  speechState: string;
  idleEndTime: string;
  movementStartTime: string;
  onStopChatting: () => void;
  fullHistory: boolean;
  language: string;
  chatBar: boolean;
  newAudioSource: string;
  isMuted: boolean;
  audioRef: React.RefObject<HTMLAudioElement>;
  backgroundColor: string | undefined;
  gradientColor: string | undefined;
  textColor: string | undefined;
  characterColor: string | undefined;
  characterText: string | undefined;
  PlayerName: string | undefined;
  myKey: string | undefined;
}
export function Chat(props: ChatProps) {
  let {
    chatHistory,
    connection,
    img,
    onStopChatting,
    chatView,
    language,
    video,
    idleStartTime,
    idleEndTime,
    speechState,
    newAudioSource,
    audioRef,
    isMuted,
    backgroundColor,
    gradientColor,
    textColor,
    characterColor,
    characterText,
    PlayerName,
    myKey,
  } = props;
  var { movementStartTime } = props;
  if (chatView === "Video") {
    movementStartTime = JSON.parse(movementStartTime).map(Number);
  }
  const {
    finalTranscript,
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  if (!browserSupportsSpeechRecognition) {
    toast.error("Browser doesn't support voice recognition");
  }
  const [showModal, setShowModal] = useState<boolean>(isMuted);
  const [currentWord, setCurrentWord] = useState<string>("");
  const [currentSentence, setCurrentSentence] = useState<string>("");
  const [mute, setmute] = useState(false);
  const [messageArray, setMessageArray] = useState<string[]>([]);
  const [isSpeechProcessing, setIsSpeechProcessing] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(true);
  const [text, setText] = useState("");
  const [view, setHideView] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [copyDestination, setCopyDestination] = useState("");
  const [copyConfirmOpen, setCopyConfirmOpen] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [showIcons, setShowIcons] = useState(false);
  const [isPlaybackMuted, setIsPlaybackMuted] = useState(
    connection.player.getMute() ?? false
  );
  const [hasPlayedWorkaroundSound, setHasPlayedWorkaroundSound] =
    useState(false);
  const localVideoRef = useRef<HTMLDivElement>(null);
  const handleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setText(e.target.value);
    },
    []
  );
  let [isAudioPlaying, setisAudioPlaying] = useState(false);
  const handleToggleIcons = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setShowIcons(!showIcons);
  };
  const [chat, setChat] = useState("");
  const [showChat, setShowChat] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Set showIcons to true when the screen width is below 425px, otherwise set to false
      setShowIcons(window.innerWidth < 767);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Call handleResize initially
    handleResize();

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggleMute = () => {
    // Toggle the mute state
    setmute((prevmute) => !prevmute);
    console.log(mute);
    // Toggle the audio mute state
    if (audioRef.current) {
      audioRef.current.muted = !mute;
    }
  };

  const handleCopyClick = useCallback(async () => {
    const history = connection.getTranscript();

    if (navigator.clipboard) {
      navigator.clipboard.writeText(history).then(() => {
        setCopyDestination("clipboard");
      });
    } else {
      setCopyDestination("console");
    }

    setCopyConfirmOpen(true);
  }, [connection, chatHistory]);

  const handleHide = () => {
    setHideView(!view);
  };

  const handleMutePlayback = useCallback(() => {
    connection.recorder.initPlayback();
    connection.player.mute(!isPlaybackMuted);
    setIsPlaybackMuted(!isPlaybackMuted);
    connection.sendTTSPlaybackMute(!isPlaybackMuted);
  }, [connection, isPlaybackMuted]);

  const playWorkaroundSound = useCallback(() => {
    // Workaround for browsers with restrictive auto-play policies
    connection.player.playWorkaroundSound();
    setHasPlayedWorkaroundSound(true);
  }, [connection, setHasPlayedWorkaroundSound]);

  const handleSend = useCallback(async () => {
    if (!language.includes("en") || !language.includes("en-uk")) {
      if (text) {
        !hasPlayedWorkaroundSound && playWorkaroundSound();
        setMessageArray((prevArray) => [...prevArray, text]);
        const newText = await translate(text);
        connection?.sendText(text);
        setText("");
      }
    }
  }, [connection, hasPlayedWorkaroundSound, playWorkaroundSound, text]);

  const handleTextKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        handleSend();
      }
    },
    [handleSend]
  );

  async function translate(text: string): Promise<string> {
    try {
      const response = await http.post(
        "/speech/translateInputText",
        {
          text: text, // Include the text parameter in the request payload
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = response.data;
      return data.text; // Return the translated text
    } catch (error) {
      console.error("Error sending audio data:", error);
      throw error; // Rethrow the error to propagate it further if needed
    }
  }
  const stopListnening = async () => {
    SpeechRecognition.stopListening();
    setIsSpeechProcessing(false);
  };

  const voice = async () => {
    if (language.includes("en")) {
      if (isAudioPlaying === false) {
        if (transcript) {
          connection?.sendText(transcript);
          setMessageArray((prevArray) => [...prevArray, transcript]);
        }
      } else {
        // toast.warning("Please Speak Clearly");
      }
    } else {
      if (isAudioPlaying === false) {
        if (transcript) {
          const responsedText = await translate(transcript);
          setMessageArray((prevArray) => [...prevArray, transcript]);
          connection?.sendText(responsedText);
        }
      } else {
        //toast.warning("Please Speak Clearly");
      }
    }
  };

  useEffect(() => {
    if (transcript !== "") {
      const words = transcript.split(" ");
      const latestWord = words[words.length - 1];
      setCurrentWord(latestWord);
      // Update the sentence with the entire transcript
      setCurrentSentence(transcript);
      // Show the sentence and update word by word
      if (isAudioPlaying) {
        setShowChat(true);
      }
      // Reset the sentence after a certain duration (adjust the duration as needed)
      const timeoutId = setTimeout(() => {
        setShowChat(false);
        setCurrentSentence("");
        setChat("");
      }, 6000);
    }
  }, [transcript]);

  // useEffect(() => {
  //   if (finalTranscript !== "") {
  //     // Reset the transcript and sentence
  //     resetTranscript();
  //     // Process the final transcript
  //     voice();
  //   }
  // }, [finalTranscript]);

  useEffect(() => {
    // Update the chat with the current sentence
    if (currentSentence !== "") {
      setChat(currentSentence);
    }

    // Add any additional logic you need based on the current word or sentence
  }, [currentWord, currentSentence]);

  const startListening = useCallback(() => {
    resetTranscript();
    SpeechRecognition.startListening({ continuous: true, language });
  }, [language]);

  const handleSpeakClick = async () => {
    console.log("Speaking");
    setIsSpeaking(true);
    if (audioRef.current?.src === "") {
      audioRef.current?.play();
      setisAudioPlaying(true);
      stopListnening();
      setShowChat(true);
    }

    // Check if speech recognition is currently active
    if (isRecording) {
      setIsRecording(!isRecording);
      setisAudioPlaying(false);
      voice();
      stopListnening();
    } else {
      setIsRecording(!isRecording);
      setisAudioPlaying(false);
      startListening();
    }
  };

  // useEffect(() => {
  //   const handleAudioEnded = () => {
  //     resetTranscript();
  //     if (isRecording) {
  //       startListening();
  //     }
  //   };
  //   audioRef.current?.addEventListener("ended", handleAudioEnded);
  //   return () =>
  //     audioRef.current?.removeEventListener("ended", handleAudioEnded);
  // }, [audioRef, isRecording, resetTranscript, startListening]);

  useEffect(() => {
    handleMutePlayback();
    if (isMuted) {
      handleToggleMute();
    }
  }, []);

  useEffect(() => {
    const isSafari = () => {
      const ua = navigator.userAgent.toLowerCase();
      return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
    };

    // check if user agent is safari and we have the ref to the container <div />
    if (isSafari() && localVideoRef.current) {
      // obtain reference to the video element
      const player = localVideoRef.current.children[0] as HTMLVideoElement;

      // if the reference to the video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the webkit Policy
        player.controls = false;
        player.playsInline = true;
        player.muted = true;
        player.setAttribute("muted", ""); // leave no stones unturned :)
        player.autoplay = true;

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed cross-browser.
          const promise = player.play();
          // let's play safe to ensure that if we do have a promise
          if (promise && promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                // if the promise fails, hide the video and fallback to <img> tag
                if (localVideoRef.current) {
                  localVideoRef.current.style.display = "none";
                }
                // Assuming avatar is a state variable, update it accordingly
                // e.g., setAvatar({ ...avatar, animation: 'Default' });
              });
          }
        }, 0);
      }
    }
  }, []); // Add dependencies if needed

  async function modalButtonHandler() {
    // handleSpeakClick();
    setShowModal((showModal) => !showModal);
  }

  useEffect(() => {
    const animationInterval = setInterval(() => {
      const videoElement = localVideoRef.current?.children[0] as
        | HTMLVideoElement
        | undefined;

      if (!videoElement) {
        return; // Exit early if the video element is not present
      }

      const currentTime = videoElement.currentTime;
      const isIdle = speechState === "idle";

      if (isIdle && currentTime > parseFloat(idleEndTime)) {
        // Reset to idle start time when the video reaches idle end time
        videoElement.currentTime = parseFloat(idleStartTime);
      } else if (!isIdle && currentTime <= parseFloat(idleEndTime)) {
        // Set random time during speech when the video is within idle end time
        const randomIndex = Math.floor(
          Math.random() * movementStartTime.length
        );
        const randomTime = parseFloat(movementStartTime[randomIndex]);
        videoElement.currentTime = randomTime;
      }
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(animationInterval);
  }, [speechState, idleStartTime, idleEndTime, movementStartTime]);

  return (
    <div
      className="right-div"
      style={{
        backgroundColor: `${
          (chatView === "Text" && backgroundColor) ||
          (chatView === "Video" && backgroundColor)
        }`,
      }}
    >
      <Box
        className={`input-main-box ${
          language.includes("ar") && "reverse"
        } content-div`}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: `${chatView === "Text" ? "100%" : "50%"}`,
          height: "100%",
          position: "relative",
          paddingBottom: `${chatView === "Text" && "4.5rem"}`,
          overflow: "hidden",
          zIndex: 2,
          backgroundColor: `${
            (chatView === "Text" && backgroundColor) ||
            (chatView === "Video" && backgroundColor)
          }`,
        }}
      >
        {currentSentence && !isAudioPlaying && (
          <div className="audio-input">{currentSentence}</div>
        )}
        {!showModal && (
          <div id="popupModal" className="modal">
            <div className="modal-content">
              {/* <video>
                <source src={video} type="video/mp4" />
              </video> */}
              <img src={img} />
              <button id="modalButton" onClick={modalButtonHandler}>
                {myKey === "nhcarabic" ? "ابدأ التفاعل" : "Start Interaction"}
              </button>
            </div>
          </div>
        )}
        {chatView === "Text" && img && (
          <div
            className="header-div"
            style={{ backgroundColor: `${characterColor}` }}
          >
            <div className="flex ">
              <img className="header-logo" src={img} alt="Logo" />
              <div className="header-text" style={{ color: `${textColor}` }}>
                {characterText}
              </div>
            </div>
          </div>
        )}
        {chatView === "Video" && video && (
          <div
            ref={localVideoRef}
            dangerouslySetInnerHTML={{
              __html: `
    <video
      loop
      muted
      autoplay
      playsinline
      preload="metadata"
      class="backgroundVid"
    >
    <source src="${video}" type="video/mp4" />
    </video>
    `,
            }}
          />
        )}

        <History
          history={chatHistory}
          chatView={props.chatView}
          emotions={props.emotions}
          img={img}
          language={props.language}
          fullHistoryVar={props.fullHistory}
          chatBar={props.chatBar}
          messageArray={messageArray}
          isHidden={view}
          gradientColor={gradientColor}
          characterColor={characterColor}
          textColor={textColor}
          myKey={myKey}
        />

        {!props.chatBar && (
          <>
            <IconButton
              className="mic-icon mic-icon-avatar"
              onClick={handleSpeakClick}
              sx={{
                height: "3rem",
                width: "3rem",
                backgroundColor: `${backgroundColor}`,
                color: `${textColor}`,
              }}
            >
              {isRecording ? <RecordIcon /> : <Mic />}
            </IconButton>
            {/* <audio ref={audioRef}>
            <source src={newAudioSource} type="audio/mp3" />
          </audio> */}
          </>
        )}
        <CopyConfirmedDialog
          copyConfirmOpen={copyConfirmOpen}
          copyDestination={copyDestination}
          setCopyConfirmOpen={setCopyConfirmOpen}
        />
        <ToastContainer />
      </Box>
      {props.chatBar && showModal && (
        <ActionsStyled
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            backgroundColor: `${
              (chatView === "Text" && backgroundColor) ||
              (chatView === "Video" && backgroundColor)
            }`,
            zIndex: 10,
          }}
          className={`${language.includes("ar") && "reverse"}`}
        >
          {/* {showChat ? (
            <p className="chat-show">
              {PlayerName}: {chat}
            </p>
          ) : (
            ""
          )} */}
          <TextField
            variant="standard"
            value={text}
            onChange={handleTextChange}
            onKeyPress={handleTextKeyPress}
            placeholder={`${characterText}`}
            className={`user-input  ${
              language.includes("ar") && "user-input-reverse"
            } text`}
            sx={{
              backgroundColor: `${characterColor}`,
              borderRadius: "35px",
              paddingTop: `${chatView === "Text" ? ".2rem" : "0.5rem"}`,
              paddingBottom: `${chatView === "Text" ? ".2rem" : "0.5rem"}`,
              paddingLeft: `${language.includes("ar") ? "0" : "1rem"}`,
              paddingRight: "1rem",
              border: "1px solid #faf8f5c2",
              color: `${textColor}`,
              flexGrow: chatView === "Text" ? 1 : 0,
              width: `${window.innerWidth > 767 && "30%"}`,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{ color: `${textColor}` }}
                    onClick={handleSend}
                  >
                    <Send />
                  </IconButton>
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
          />

          <IconButton
            className="mic-icon"
            onClick={handleSpeakClick}
            sx={{
              height: "3rem",
              width: "3rem",
              backgroundColor: `${characterColor}`,
              color: `${textColor}`,
            }}
          >
            {isRecording ? <RecordIcon /> : <Mic />}
          </IconButton>
          {!isMuted &&
            (mute ? (
              <IconButton
                onClick={handleToggleMute}
                sx={{
                  height: "3rem",
                  width: "3rem",
                  backgroundColor: `${characterColor}`,
                  color: `${textColor}`,
                }}
              >
                <VolumeOff
                  sx={{
                    color: textColor,
                  }}
                  fontSize="small"
                />
              </IconButton>
            ) : (
              <IconButton
                onClick={handleToggleMute}
                sx={{
                  height: "3rem",
                  width: "3rem",
                  backgroundColor: `${characterColor}`,
                  color: `${textColor}`,
                }}
              >
                <VolumeUp sx={{ color: textColor }} fontSize="small" />
              </IconButton>
            ))}
          <IconButton
            onClick={handleHide}
            sx={{
              height: "3rem",
              width: "3rem",
              backgroundColor: `${characterColor}`,
              color: `${textColor}`,
            }}
          >
            <SmsIcon sx={{ color: `${textColor}` }} fontSize="small" />
          </IconButton>
          <div>
            <audio ref={audioRef}>
              <source src={newAudioSource} type="audio/mp3" />
            </audio>
          </div>
          <div className="icon-wrapper">
            <IconButton
              onClick={handleToggleIcons}
              sx={{ height: "3rem", width: "3rem" }}
              className="toggle-icon"
            >
              <MoreVertIcon style={{ fill: `${textColor}` }} />
            </IconButton>
            <div className={!showIcons ? "icons" : "icons show"}>
              <IconButton
                onClick={onStopChatting}
                sx={{ height: "3rem", width: "3rem" }}
              >
                <SettingsBackupRestoreOutlinedIcon
                  style={{ fill: `${textColor}` }}
                />
              </IconButton>

              <IconButton onClick={handleCopyClick}>
                <CopyAll sx={{ color: `${textColor}` }} fontSize="small" />
              </IconButton>
            </div>
          </div>
        </ActionsStyled>
      )}
    </div>
  );
}

{
  /* <Box
className="icon-div"
sx={{
  display: "flex",
  flexDirection: "row",
  justifyContent: "",
  width: "50%",
  paddingLeft: "12px ",
  position: "relative",
  marginTop: "auto",
  overflow: "hidden",
  zIndex: 2,
  backgroundColor: `${chatView === "Text" ? backgroundColor : "#000"}`,
}}
>
<IconButton
  onClick={onStopChatting}
  sx={{ height: "3rem", width: "3rem" }}
>
  <SettingsBackupRestoreOutlinedIcon style={{ fill: `${textColor}` }} />
</IconButton>
<IconButton onClick={handleToggleMute}>
  {isMuted ? (
    // <VolumeOff sx={{ color: "#F1F5F9" }} fontSize="small" />
    <span></span>
  ) : (
    <VolumeUp sx={{ color: `${textColor}` }} fontSize="small" />
  )}
</IconButton>
{/* <IconButton
  className="mic-icon"
  onClick={handleSpeakClick}
  sx={{ height: "3rem", width: "3rem", backgroundColor: "#F1F5F9" }}
>
  {isRecording ? <RecordIcon /> : <Mic />}
</IconButton> }
<IconButton onClick={handleCopyClick}>
  <CopyAll sx={{ color: `${textColor}` }} fontSize="small" />
</IconButton>
</Box> */
}
