import {
  Actor,
  CHAT_HISTORY_TYPE,
  HistoryItem,
  HistoryItemActor,
  HistoryItemNarratedAction,
  HistoryItemTriggerEvent,
} from "@inworld/web-sdk";
import { Box, Fade, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "../styles/History.css";
import { getEmoji } from "../helpers/emoji";
import { dateWithMilliseconds } from "../helpers/transform";
import { CHAT_VIEW, EmotionsMap } from "../types";
import http from "../services/http";
import {
  HistoryAction,
  HistoryActor,
  HistoryInner,
  HistoryItemMessageActor,
  HistoryMessageGroup,
  HistoryStyled,
} from "./Chat.styled";
import { Typing } from "./Typing";

interface HistoryProps {
  chatView: CHAT_VIEW;
  history: HistoryItem[];
  emotions: EmotionsMap;
  img: string;
  fullHistoryVar: boolean;
  language: string;
  chatBar: boolean;
  messageArray: string[];
  isHidden: boolean;
  gradientColor: string | undefined;
  characterColor: string | undefined;
  textColor: string | undefined;
  myKey: string | undefined;
}

type CombinedHistoryItem = {
  interactionId: string;
  messages: (
    | HistoryItemActor
    | HistoryItemNarratedAction
    | HistoryItemTriggerEvent
  )[];
  source: Actor;
  type: CHAT_HISTORY_TYPE;
};

export const History = (props: HistoryProps) => {
  const {
    chatView,
    history,
    img,
    messageArray,
    isHidden,
    gradientColor,
    characterColor,
    textColor,
    language,
    myKey,
  } = props;

  const ref = useRef<HTMLDivElement>(null);

  const [combinedChatHistory, setCombinedChatHistory] = useState<
    CombinedHistoryItem[]
  >([]);
  const [isInteractionEnd, setIsInteractionEnd] = useState<boolean>(true);

  useEffect(() => {
    // scroll chat down on history change
    if (ref.current && history) {
      ref.current.scrollTo({
        top: ref.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [history]);

  const fetchData = async (
    textData: string | undefined
  ): Promise<string | undefined> => {
    try {
      const response = await http.post(
        "/speech/translateInputText",
        {
          text: textData,
          language: props.language,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      return response.data.text;
    } catch (err) {
      console.error("Error:", err);
    }
  };

  useEffect(() => {
    let tempFunc = async () => {
      let currentRecord: CombinedHistoryItem | undefined;
      let index: number = 0;

      const hasActors = history.find(
        (record: HistoryItem) => record.type === CHAT_HISTORY_TYPE.ACTOR
      );
      const withoutTriggerEvents = history.filter((record: HistoryItem) =>
        [CHAT_HISTORY_TYPE.ACTOR, CHAT_HISTORY_TYPE.INTERACTION_END].includes(
          record.type
        )
      );
      if (history.length > 0) {
        let item =
          history.length === 1 ? history[0] : history[history.length - 1];
        switch (item.type) {
          case CHAT_HISTORY_TYPE.ACTOR:
          case CHAT_HISTORY_TYPE.NARRATED_ACTION:
            if (item.source.isCharacter) {
              if (!language.includes("en") || !language.includes("en-uk")) {
                var responseText = await fetchData(item.text);

                item.text = responseText;
              }
            } else {
              if (messageArray[index]) {
                item.text = messageArray[messageArray.length - 1];
                index++;
              }
            }
            currentRecord = combinedChatHistory.find(
              (r) =>
                r.interactionId === item.interactionId &&
                [
                  CHAT_HISTORY_TYPE.ACTOR,
                  CHAT_HISTORY_TYPE.NARRATED_ACTION,
                ].includes(r.messages?.[0]?.type) &&
                r.type === CHAT_HISTORY_TYPE.ACTOR &&
                r.source.name === item.source.name
            ) as CombinedHistoryItem;

            if (currentRecord) {
              currentRecord.messages.push(item);
            } else {
              currentRecord = {
                interactionId: item.interactionId,
                messages: [item],
                source: item.source,
                type: CHAT_HISTORY_TYPE.ACTOR,
              } as CombinedHistoryItem;
              combinedChatHistory.push(currentRecord);
            }
            break;
          case CHAT_HISTORY_TYPE.TRIGGER_EVENT:
            // item.source.isCharacter?item.text=messageArray[i]:console.log("same")
            combinedChatHistory.push({
              interactionId: item.interactionId!,
              messages: [item],
              source: item.source,
              type: item.type,
            });
            break;
        }
        // }
      }
      // Interaction is considered ended
      // when there is no actor action yet (chat is not started)
      // or last received message is INTERACTION_END.
      const lastInteractionId =
        withoutTriggerEvents[withoutTriggerEvents.length - 1]?.interactionId;

      const interactionEnd = withoutTriggerEvents.find(
        (event) =>
          event.interactionId === lastInteractionId &&
          event.type === CHAT_HISTORY_TYPE.INTERACTION_END
      );

      setIsInteractionEnd(!hasActors || (!!currentRecord && !!interactionEnd));

      // setCombinedChatHistory(mergedRecords);
    };
    tempFunc();
  }, [history]);

  const getContent = (
    message:
      | HistoryItemActor
      | HistoryItemNarratedAction
      | HistoryItemTriggerEvent
  ) => {
    switch (message.type) {
      case CHAT_HISTORY_TYPE.ACTOR:
        return message.text;
      case CHAT_HISTORY_TYPE.NARRATED_ACTION:
        return <HistoryAction>{message.text}</HistoryAction>;
      case CHAT_HISTORY_TYPE.TRIGGER_EVENT:
        return message.name;
    }
  };

  return (
    <HistoryStyled
      className={`${`history--avatar-view`} ${
        props.chatBar ? "bottomPadding" : ""
      }`}
    >
      <HistoryInner>
        <Box
          ref={ref}
          className="history--avatar-list"
          style={{ overflowY: chatView === "Text" ? "scroll" : "scroll" }}
        >
          {combinedChatHistory.map((item, index) => {
            // let emoji: string | null = null;
            let messages = item.messages;
            let actorSource = "AGENT";
            let message = item.messages?.[0];
            const title =
              item.type === CHAT_HISTORY_TYPE.ACTOR ||
              item.type === CHAT_HISTORY_TYPE.TRIGGER_EVENT
                ? `${dateWithMilliseconds(message.date)} (${
                    item.interactionId
                  })`
                : "";

            if (item.type === CHAT_HISTORY_TYPE.ACTOR) {
              actorSource = item.source.isCharacter ? "AGENT" : "PLAYER";

              if (item.source.isCharacter) {
                const emotion = props.emotions[item.interactionId!];
                // if (emotion?.behavior) {
                //   emoji = getEmoji(emotion.behavior);
                // }
              }
            }

            return (
              <HistoryMessageGroup
                key={`PortalSimulatorChatHistoryMessageGroup-${index}`}
                className={`${
                  props.fullHistoryVar ? "" : "history-message-group"
                } history-message-group--${actorSource}`}
              >
                <HistoryActor
                  className={`chat__bubble ${
                    props.language === "ar" ? "chat__arabic" : " "
                  }`}
                  title={title}
                  key={index}
                  data-id={message.id}
                >
                  <div className="chat-d">
                    <div>
                      {myKey !== "SKULLY2" && chatView !== "Video" && (
                        <img style={{ width: "2rem" }} src={img} alt="" />
                      )}
                    </div>
                    <Stack
                      sx={{ maxWidth: ["90%", "85%"] }}
                      flexDirection={"row"}
                      alignItems="center"
                    >
                      <HistoryItemMessageActor
                        className="history-actor"
                        style={{
                          display: isHidden ? "none" : "block",
                          backgroundColor:
                            actorSource === "PLAYER"
                              ? characterColor
                              : gradientColor,
                          color: textColor,
                        }}
                        key={`PortalSimulatorChatHistoryActor-${index}`}
                      >
                        {/* {emoji && (
                          <Box
                            className="simulator-message__emoji"
                            fontSize={16}
                          >
                            {emoji}
                          </Box>
                        )} */}
                        <Typography>
                          {messages.map((m) => (
                            <React.Fragment key={m.id}>
                              {getContent(m)}{" "}
                            </React.Fragment>
                          ))}
                        </Typography>
                      </HistoryItemMessageActor>
                    </Stack>
                  </div>
                </HistoryActor>
              </HistoryMessageGroup>
            );
          })}
          {/* <Fade in={!isInteractionEnd} timeout={500}>
            <Box margin="">
              <Typing />
            </Box>
          </Fade> */}
        </Box>
      </HistoryInner>
    </HistoryStyled>
  );
};
