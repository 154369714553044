import { Character, EmotionEvent } from "@inworld/web-sdk";

export enum CHAT_VIEW {
  TEXT = "Text",
  AVATAR = "Avatar",
  VIDEO = "Video",
}

export type ConfigurationCharacter = {
  name?: string;
};

export type ConfigurationScene = {
  name?: string;
};
export type ConfigurationBackground = {
  name?: string;
};
export type ConfigurationGradient = {
  name?: string;
};
export type ConfigurationText = {
  name?: string;
};
export type ConfigurationCharacterColor = {
  name?: string;
};

export type ConfigurationPlayer = {
  name?: string;
};
export type ConfigurationImg = {
  name?: string;
};
export type ConfigurationVideo = {
  name?: string;
};
export type ConfigurationIdleStartTime = {
  name?: string;
};
export type ConfigurationIdleEndTime = {
  name?: string;
};
export type ConfigurationMovementStartTime = {
  name?: string;
};
export type ConfigurationKey = {
  name?: string;
};
export type ConfigurationisMuted = {
  name?: boolean;
};

export type Configuration = {
  character?: ConfigurationCharacter;
  scene?: ConfigurationScene;
  player?: ConfigurationPlayer;
  chatView?: CHAT_VIEW;
  img?: ConfigurationImg;
  video?: ConfigurationVideo;
  idleStartTime?: ConfigurationIdleStartTime;
  isMuted?: ConfigurationisMuted;
  idleEndTime?: ConfigurationIdleEndTime;
  movementStartTime?: ConfigurationMovementStartTime;
  backgroundColor?: ConfigurationBackground;
  gradientColor?: ConfigurationGradient;
  textColor?: ConfigurationText;
  characterColor?: ConfigurationCharacterColor;
  characterText?: ConfigurationText;
  key?: ConfigurationKey;
};

export interface EmotionsMap {
  [key: string]: EmotionEvent;
}
export enum CHAT_HISTORY_TYPE {
  ACTOR = "actor",
  CUSTOM_EVENT = "custom_event",
}

export interface ChatHistoryItemBase {
  id: string;
  type: CHAT_HISTORY_TYPE;
}

export interface ChatHistoryItemActor extends ChatHistoryItemBase {
  type: CHAT_HISTORY_TYPE.ACTOR;
  isCharacter: boolean;
  text?: string;
  date?: Date;
  emotions?: EmotionEvent;
  interactionId?: string;
  isRecognizing?: boolean;
  character?: Character;
}

export interface ChatHistoryItemCustomEvent extends ChatHistoryItemBase {
  type: CHAT_HISTORY_TYPE.CUSTOM_EVENT;
  name: string;
  date?: Date;
  interactionId?: string;
  isCharacter: boolean;
}

export type ChatHistoryItem = ChatHistoryItemActor | ChatHistoryItemCustomEvent;
